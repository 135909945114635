import React from "react";
import moment from "moment";
import {graphql, useStaticQuery} from "gatsby";
import _ from "lodash";

import {formatReleaseIssue} from "../utils/formatter";
import {ServerBadge} from "./badge";

function getTags(issues) {
    const scopeList = issues.map(({scope}) => scope);
    const scopeTags = _.flatMap(scopeList, scope => scope.split(/[,/]\s*/));
    const extractedTags = scopeTags
        .map(tag => tag.startsWith("SQL Server") ? "SQL Server" : tag)
        .map(tag => tag.startsWith("ODBC") ? "ODBC" : tag)
        .map(tag => tag.startsWith("Oracle") ? "Oracle" : tag);

    const tags = _.uniq(extractedTags);
    return _.sortBy(tags, tag => "General" === tag ? "" : tag);
}


const Issues = ({title, issues}) => (
    <>
        <h6 className="text-uppercase small mt-3">{title}</h6>
        <ul>
            {issues.map(({scope, description}, index) => (
                <li key={`issue-${index}`}>
                    <span className="text-secondary">{scope}:</span>{' '}
                    {formatReleaseIssue(description)}
                </li>
            ))}
        </ul>
    </>
);

const Release = ({date, version, issues}) => {
    const features = issues.filter(({feature}) => !!feature);
    const defects = issues.filter(({feature}) => !feature);

    return (
        <>
            <div className="d-flex">
                <div>
                    <div className="h5 mb-0 text-info">{version}</div>
                    <div className="text-muted">{moment(date).format("MMMM DD, YYYY")}</div>
                </div>
            </div>
            <div>
                {getTags(issues).map((tag, index) => (
                    [' ', <ServerBadge key={`tag-${index}`}>{tag}</ServerBadge>]
                ))}
            </div>
            {features.length > 0 &&
            <Issues title="New Features" issues={features}/>}
            {defects.length > 0 &&
            <Issues title="Bug Fixes" issues={defects}/>}
        </>
    );
};


const ReleaseHistory = ({limit}) => {
    const {releaseHistory: {releases}} = useStaticQuery(
        graphql`
query {
    releaseHistory {
        releases {
            version
            date
            issues {
                scope
                description
                feature
            }
        }
    }
}
`);

    return (
        <>
            {(limit ? releases.slice(0, limit) : releases).map(({date, version, issues}, index) => (
                <Release key={`release-${index}`} date={date} version={version} issues={issues}/>
            ))}
        </>
    )
};

export default ReleaseHistory;

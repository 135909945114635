import React from "react";

function formatReleaseIssue(description) {
    const text = description.endsWith(".") ? description.slice(0, -1) : description;
    const words = text.split(/([\s,])/g);

    const {items, code} = words.reduce((memo, word) => {
        const {code, items: [lastText, ...rest]} = memo;

        const apiMethod = /^\w+::\w+(\(.*?\))?$/.test(word);
        const apiOption = /^[A-Z0-9]+(_[A-Z0-9]+)+$|^"[A-Z0-9]+(_[A-Z0-9]+)+"$|^"[A-Za-z0-9]+"$/.test(word);
        if (!apiMethod && !apiOption) {
            return {
                ...memo,
                items: [`${lastText}${word}`, ...rest]
            };
        }

        return {
            ...memo,
            items: ['', <code key={`code-${code}`}>{word}</code>, lastText, ...rest],
            code: code + 1
        }
    }, {
        code: 0,
        items: ['']
    });

    if (!code) {
        return text;
    }

    return items.filter(item => item !== '').reverse();
}

export {formatReleaseIssue};
